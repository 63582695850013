import React, { Component } from 'react'
import { connect } from 'react-redux'
import Background from '../components/page_component/Background'
import BgGlassmorphism from '../components/page_component/BgGlassmorphism'
import Countries from '../components/page_component/countries/Countries'
import Country from '../pagess/Country'
import Discipline from '../components/page_component/disciplines/Discipline'
import Institutions from '../components/page_component/institutions/Institutions'
import Program from '../components/page_component/programs/Program'
import SectionHero from '../components/page_component/SectionHero'
import AppliedPrograms from './worker_pages/AppliedPrograms'
import { mainDashboardTranslate, getUnirepRoleTranslate } from '../actions/TranslateAction';
import Comments from '../components/page_component/comments/Comments'
import Messages from './Messages'
import Universities from './Universities'
import TranslatorMainPage from './TranslatorMainPage'
import Skeleton from '../components/page_component/Skeleton'
import { useNavigate } from 'react-router-dom'
import LoginPage from './LoginPage'
import CompanyInOuts from './worker_pages/CompanyInOuts'
function withHooks(Component){
  return props => <Component {...props} navigate={useNavigate()} />
}
export class MainPage extends Component {
  componentDidMount(){
    window.scrollTo(0, 0);
    this.props.mainDashboardTranslate(this.props.languageId);
    this.props.getUnirepRoleTranslate(this.props.languageId);
   
  }
  render() {
    const {user, mainTransateData, unirepRoleTranslate} = this.props;

    return (
      <div className="nc-PageHome relative overflow-hidden">
        {/* <BgGlassmorphism /> */}
        <div className={`${user == ''? 'container':''} relative space-y-24 mb-24 lg:space-y-28 lg:mb-28`}>
         
          {
             user !== '' && user?.role == 'student' || user !== '' && user?.role == 'edu_consultant' 
            || user !== '' && user?.role == 'parent' || user !== '' && user?.role == 'teacher'?
            <Messages />:null
          }
          {
            user !== '' && user?.role == 'uni_rep' ?
            <CompanyInOuts />:null
          }
          {/* {
            user !== '' && user?.role == 'uni_rep' ?
            <Country />:null
          } */}
          {
            user !== '' && user?.role == 'content'?
            <Universities />:null
          }
          {
            user !== '' && user?.role == 'translator'?
            <TranslatorMainPage />:null
          }
          {
            user === ''?
            <>
            <LoginPage />
             {/* <SectionHero />
             <div className="relative py-16">
              <Background />
              <Countries type="home_page" page={8} btn={'view_all'} />
            </div> */}
            {/* <div className="relative py-16">
              <Institutions filter={false} page={8} type="home_page" btn={'view_all'}  />
            </div> */}
            {/* <Discipline page={8} btn={'view_all'} /> */}
            {/* <div className="relative py-16">
              <Background />
              <Program filter={false} page={8} btn={'view_all'} />
            </div> */}
            {/* <div className="relative py-16">
              <Background />
              <Comments />
            </div> */}
          

          
            
      
            </>:null
          }
          
        </div>
       </div>
    )
  }
}
const mapStateToProps = (state)=>({
  user: state.Data.user,
  languageId: state.Data.languageId,
  mainTransateData: state.Data.mainTransateData,
  unirepRoleTranslate: state.Data.unirepRoleTranslate,

});
const mapDispatchToProps = {mainDashboardTranslate, getUnirepRoleTranslate}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(MainPage))