import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import DropDownMenu from './DropDownMenu';
export class Navigation extends Component {
    state={
        
    }
  render() {
    // const {navigations, unirepNavigations, studentNavigation} = this.state;
    const {user, mainTransateData, unirepRoleTranslate} = this.props;
    const teacherNavigation = [
      {
        id: 1,
        href: "/",
        name: mainTransateData?.inbox_nav,
        label:'mainTransateData.inbox_nav'
        // name: unirepRoleTranslate?.nav_2,
        // label:'unirepRoleTranslate.nav_2'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 4,
        href: "/programs",
        name: unirepRoleTranslate?.nav_4,
        label:'unirepRoleTranslate.nav_4'
        // type: "megaMenu",
        // megaMenu: megaMenuDemo,
      },
      {
        id: 2,
        href: "/students",
        name: unirepRoleTranslate?.nav_3,
        label:'unirepRoleTranslate.nav_3'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 6,
        href: "/parents",
        name: mainTransateData?.nav_7,
        label:'mainTransateData.nav_7'
      },
      
    ];
    const navigations = [
      {
        id: 1,
        href: "/institutions",
        name: mainTransateData?.nav_1,
        label:'mainTransateData.nav_1'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 2,
        href: "/programs",
        name: mainTransateData?.nav_3,
        label:'mainTransateData.nav_3'
        // type: "megaMenu",
        // megaMenu: megaMenuDemo,
      },
      {
        id: 3,
        href: "/countries",
        name: mainTransateData?.nav_4,
        label:'mainTransateData.nav_4'
        
      },
      {
        id: 4,
        href: "/schoolarships",
        name: mainTransateData?.nav_2,
        label:'mainTransateData.nav_2'
      },
      {
        id: 5,
        href: "/disciplines",
        name: mainTransateData?.nav_6,
        label:'mainTransateData.nav_6'
      },
      {
        id: 6,
        href: "/accepted-students",
        name: mainTransateData?.accepted_students,
        label:'mainTransateData.accepted_students'
      },

      
    ];
    const unirepNavigations =[
      {
        id: 3,
        href: "/",
        name: 'Comps',
        label:'unirepRoleTranslate.nav_5'
        
      },
      {
        id: 1,
        href: "/country",
        name: 'Countries',
        label:'mainTransateData.inbox_nav'
        // name: unirepRoleTranslate?.nav_2,
        // label:'unirepRoleTranslate.nav_2'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 7,
        href: "/accepted-students",
        name: 'Acc. Stud',
        label:'mainTransateData.accepted_students'
      },
      
      {
        id: 1,
        href: "/documents",
        name: 'Docs',
        label:'unirepRoleTranslate.nav_12'
        // name: unirepRoleTranslate?.nav_2,
        // label:'unirepRoleTranslate.nav_2'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 2,
        href: "/institutions",
        name: unirepRoleTranslate?.nav_1,
        label:'unirepRoleTranslate.nav_1'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
     
     
    ];
    const unirepDrpDown = [
      {
        id: 3,
        href: "/uni-seo",
        name: 'Uni SEO',
        label:'unirepRoleTranslate.nav_5'
        
      },
      {
        id: 3,
        href: "/upload-uni",
        name: 'Upload University',
        label:'unirepRoleTranslate.nav_5'
        
      },
      {
        id: 3,
        href: "/add-all-auto",
        name: 'Add All Data',
        label:'unirepRoleTranslate.nav_5'
        
      },
      {
        id: 3,
        href: "/country-robo",
        name: 'Robo for Countries',
        label:'unirepRoleTranslate.nav_5'
        
      },
      {
        id: 3,
        href: "/uni-robo",
        name: 'Robo for Uni',
        label:'unirepRoleTranslate.nav_5'
        
      },
      
      {
        id: 3,
        href: "/commissions",
        name: 'Commissions',
        label:'unirepRoleTranslate.nav_5'
        
      },
     
      {
        id: 7,
        href: "/edu-con",
        name: unirepRoleTranslate?.nav_11,
        label:'unirepRoleTranslate.nav_11'
      },
      {
        id: 7,
        href: "/blog-topics",
        name: "Blog Topics",
        label:'unirepRoleTranslate.nav_11'
      },
      {
        id: 7,
        href: "/blogs",
        name: "Blogs",
        label:'unirepRoleTranslate.nav_11'
      },
      {
        id: 3,
        href: "/programs",
        name: unirepRoleTranslate?.nav_4,
        label:'unirepRoleTranslate.nav_4'
        // type: "megaMenu",
        // megaMenu: megaMenuDemo,
      },
      {
        id: 7,
        href: "/assign-student",
        name: unirepRoleTranslate?.nav_3,
        label:'unirepRoleTranslate.nav_3'
      },
      {
        id: 7,
        href: "/inbox-app",
        name: 'Inbox App',
        label:'unirepRoleTranslate.nav_3'
      },
     
      {
        id: 5,
        href: "/schoolarships",
        name: unirepRoleTranslate?.nav_6,
        label:'unirepRoleTranslate.nav_6'
      },
      {
        id: 6,
        href: "/disciplines",
        name: unirepRoleTranslate?.nav_7,
        label:'unirepRoleTranslate.nav_7'
      },
      {
        id: 7,
        href: "/content",
        name: mainTransateData?.content_nav,
        label:'mainTransateData.content_nav'
      },
      {
        id: 7,
        href: "/translators",
        name: mainTransateData?.translator_nav,
        label:'mainTransateData.translator_nav'
      },
      {
        id: 8,
        href: "/other-page",
        name: "Other Pages",
        label:'mainTransateData.translator_nav'
      },
    ]









    const contentNavigations =[
      
      {
        id: 2,
        href: "/",
        name: unirepRoleTranslate?.nav_1,
        label:'unirepRoleTranslate.nav_1'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },

      {
        id: 3,
        href: "/programs",
        name: unirepRoleTranslate?.nav_4,
        label:'unirepRoleTranslate.nav_4'
        // type: "megaMenu",
        // megaMenu: megaMenuDemo,
      },
      
      {
        id: 4,
        href: "/countries",
        name: unirepRoleTranslate?.nav_5,
        label:'unirepRoleTranslate.nav_5'
        
      },
      {
        id: 6,
        href: "/disciplines",
        name: unirepRoleTranslate?.nav_7,
        label:'unirepRoleTranslate.nav_7'
      },
      {
        id: 3,
        href: "/upload-uni",
        name: 'Upload University',
        label:'unirepRoleTranslate.nav_5'
        
      },
      {
        id: 3,
        href: "/add-programs-auto",
        name: 'Add Program Automate',
        label:'unirepRoleTranslate.nav_5'
        
      },
    ];
    const translatorNavigations = [
      {
        id: 1,
        href: "/",
        name: 'Content',
        label:'unirepRoleTranslate.nav_1'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 2,
        href: "/tr-countries",
        name: 'Countries',
        label:'unirepRoleTranslate.nav_1'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 3,
        href: "/tr-countries-faq",
        name: 'Countries FAQs',
        label:'unirepRoleTranslate.nav_1'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 4,
        href: "/tr-institutions",
        name: 'Universities',
        label:'unirepRoleTranslate.nav_1'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 5,
        href: "/tr-institutions-faq",
        name: 'Universities FAQs',
        label:'unirepRoleTranslate.nav_1'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },


    ]

    const studentNavigation =[
      {
        id: 1,
        href: "/",
        name: mainTransateData?.inbox_nav,
        label:'mainTransateData.inbox_nav'
        // name: unirepRoleTranslate?.nav_2,
        // label:'unirepRoleTranslate.nav_2'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 7,
        href: "/simulator",
        name: 'Apply NOW',
        label:'unirepRoleTranslate.nav_9'
      },
    
      // {
      //   id: 2,
      //   href: "/application-data",
      //   name: unirepRoleTranslate?.nav_8,
      //   label:'unirepRoleTranslate.nav_8'
      //   // type: "dropdown",
      //   // children: demoChildMenus,
      //   // isNew: true,
      // },
      // {
      //   id: 1,
      //   href: "/applications",
      //   name: unirepRoleTranslate?.nav_2,
      //   label:'unirepRoleTranslate.nav_2'
      //   // type: "dropdown",
      //   // children: demoChildMenus,
      //   // isNew: true,
      // },
      
      {
        id: 3,
        href: "/institutions",
        name: unirepRoleTranslate?.nav_10,
        label:'unirepRoleTranslate.nav_10'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      
      {
        id: 5,
        href: "/countries",
        name: unirepRoleTranslate?.nav_5,
        label:'unirepRoleTranslate.nav_5'
        
      },
      {
        id: 6,
        href: "/schoolarships",
        name: unirepRoleTranslate?.nav_6,
        label:'unirepRoleTranslate.nav_6'
      },
      
      
    ];
    
    const studentDropNavs = [
      
      // {
      //   id: 3,
      //   href: "/institutions",
      //   name: unirepRoleTranslate?.nav_10,
      //   label:'unirepRoleTranslate.nav_10'
      //   // type: "dropdown",
      //   // children: demoChildMenus,
      //   // isNew: true,
      // },
      // {
      //   id: 4,
      //   href: "/programs",
      //   name: unirepRoleTranslate?.nav_4,
      //   label:'unirepRoleTranslate.nav_4'
      //   // type: "megaMenu",
      //   // megaMenu: megaMenuDemo,
      // },
      // {
      //   id: 5,
      //   href: "/countries",
      //   name: unirepRoleTranslate?.nav_5,
      //   label:'unirepRoleTranslate.nav_5'
        
      // },
      // {
      //   id: 6,
      //   href: "/schoolarships",
      //   name: unirepRoleTranslate?.nav_6,
      //   label:'unirepRoleTranslate.nav_6'
      // },
      {
        id: 4,
        href: "/programs",
        name: unirepRoleTranslate?.nav_4,
        label:'unirepRoleTranslate.nav_4'
        // type: "megaMenu",
        // megaMenu: megaMenuDemo,
      },
      {
        id: 7,
        href: "/accepted-students",
        name: mainTransateData?.accepted_students,
        label:'mainTransateData.accepted_students'
      },
    ]
    const eduConsNavigation = [
      {
        id: 1,
        href: "/",
        name:unirepRoleTranslate?.nav_3,
        label:'unirepRoleTranslate.nav_3'
        // name: unirepRoleTranslate?.nav_2,
        // label:'unirepRoleTranslate.nav_2'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 1,
        href: "/documents",
        name: 'Docs',
        label:'unirepRoleTranslate.nav_12'
        // name: unirepRoleTranslate?.nav_2,
        // label:'unirepRoleTranslate.nav_2'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 1,
        href: "/finance",
        name: 'Finance',
        label:'unirepRoleTranslate.nav_12'
        // name: unirepRoleTranslate?.nav_2,
        // label:'unirepRoleTranslate.nav_2'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
    
      {
        id: 7,
        href: "/accepted-students",
        name: mainTransateData?.accepted_students,
        label:'mainTransateData.accepted_students'
      },
      // {
      //   id: 4,
      //   href: "/programs",
      //   name: unirepRoleTranslate?.nav_4,
      //   label:'unirepRoleTranslate.nav_4'
      //   // type: "megaMenu",
      //   // megaMenu: megaMenuDemo,
      // },
      // {
      //   id: 2,
      //   href: "/students",
      //   name: unirepRoleTranslate?.nav_3,
      //   label:'unirepRoleTranslate.nav_3'
      //   // type: "dropdown",
      //   // children: demoChildMenus,
      //   // isNew: true,
      // },
      // {
      //   id: 6,
      //   href: "/parents",
      //   name: mainTransateData?.nav_7,
      //   label:'mainTransateData.nav_7'
      // },
      

    ];

    const teacherDropDown = [
      {
        id: 3,
        href: "/institutions",
        name: unirepRoleTranslate?.nav_10,
        label:'unirepRoleTranslate.nav_10'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 5,
        href: "/countries",
        name: unirepRoleTranslate?.nav_5,
        label:'unirepRoleTranslate.nav_5'
        
      },
      {
        id: 6,
        href: "/schoolarships",
        name: unirepRoleTranslate?.nav_6,
        label:'unirepRoleTranslate.nav_6'
      },
      {
        id: 7,
        href: "/accepted-students",
        name: mainTransateData?.accepted_students,
        label:'mainTransateData.accepted_students'
      },
      
    ]
    const educonDropDown = [
      {
        id: 3,
        href: "/institutions",
        name: unirepRoleTranslate?.nav_10,
        label:'unirepRoleTranslate.nav_10'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 5,
        href: "/countries",
        name: unirepRoleTranslate?.nav_5,
        label:'unirepRoleTranslate.nav_5'
        
      },
      {
        id: 6,
        href: "/schoolarships",
        name: unirepRoleTranslate?.nav_6,
        label:'unirepRoleTranslate.nav_6'
      },
      {
        id: 6,
        href: "/teachers",
        name: mainTransateData?.teacher_nav,
        label:'mainTransateData.teacher_nav'
      },
      {
        id: 7,
        href: "/accepted-students",
        name: mainTransateData?.accepted_students,
        label:'mainTransateData.accepted_students'
      },
      
    ]
    const educonProDropDown = [
      {
        id: 3,
        href: "/institutions",
        name: unirepRoleTranslate?.nav_10,
        label:'unirepRoleTranslate.nav_10'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 5,
        href: "/countries",
        name: unirepRoleTranslate?.nav_5,
        label:'unirepRoleTranslate.nav_5'
        
      },
      {
        id: 3,
        href: "/commissions",
        name: 'Commissions',
        label:'unirepRoleTranslate.nav_5'
        
      },
      {
        id: 6,
        href: "/teachers",
        name: mainTransateData?.teacher_nav,
        label:'mainTransateData.teacher_nav'
      },
      {
        id: 7,
        href: "/accepted-students",
        name: mainTransateData?.accepted_students,
        label:'mainTransateData.accepted_students'
      },
      
    ]
    const translatorDropDowns = [
      {
        id: 1,
        href: "/tr-accomodation",
        name: 'Accomodation',
        label:'unirepRoleTranslate.nav_10'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 2,
        href: "/tr-application",
        name: 'Applications',
        label:'unirepRoleTranslate.nav_10'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 3,
        href: "/tr-visa",
        name: 'Visa',
        label:'unirepRoleTranslate.nav_10'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 4,
        href: "/tr-edu-degree",
        name: 'Education Degree',
        label:'unirepRoleTranslate.nav_1'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 5,
        href: "/tr-extra-activity",
        name: 'Extra Activity',
        label:'unirepRoleTranslate.nav_1'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 6,
        href: "/tr-int-olympiads",
        name: 'International Olympiads',
        label:'unirepRoleTranslate.nav_1'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      
    ]

    const parentNavigation = [
      {
        id: 1,
        href: "/",
        name: mainTransateData?.inbox_nav,
        label:'mainTransateData.inbox_nav'
        // name: unirepRoleTranslate?.nav_2,
        // label:'unirepRoleTranslate.nav_2'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 1,
        href: "/applications",
        name: unirepRoleTranslate?.nav_2,
        label:'unirepRoleTranslate.nav_2'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
    
      {
        id: 3,
        href: "/institutions",
        name: unirepRoleTranslate?.nav_10,
        label:'unirepRoleTranslate.nav_10'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 4,
        href: "/programs",
        name: unirepRoleTranslate?.nav_4,
        label:'unirepRoleTranslate.nav_4'
        // type: "megaMenu",
        // megaMenu: megaMenuDemo,
      },
      {
        id: 5,
        href: "/countries",
        name: unirepRoleTranslate?.nav_5,
        label:'unirepRoleTranslate.nav_5'
        
      },
      
    ]

    const parentDropDowns = [
      {
        id: 6,
        href: "/schoolarships",
        name: unirepRoleTranslate?.nav_6,
        label:'unirepRoleTranslate.nav_6'
      },
      {
        id: 7,
        href: "/accepted-students",
        name: mainTransateData?.accepted_students,
        label:'mainTransateData.accepted_students'
      },
    ]
    let navs = [];
    if(user === ''){
      navs = navigations
    }else if(user.role == 'uni_rep'){
      navs = unirepNavigations
    }else if(user.role == 'student'){
      navs = studentNavigation
    }else if(user.role == 'edu_consultant'){
      navs = eduConsNavigation
    }else if(user.role == 'parent'){
      navs = parentNavigation
    }else if(user.role == 'content'){
      navs = contentNavigations
    }else if(user.role == 'translator'){
      navs = translatorNavigations
    }else if(user.role == 'teacher'){
      navs = teacherNavigation
    }

    return (
      <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
     
      {navs.map((item, i) => (
        <NavLink
            // exact={true}
            // strict
            key={i}
            rel="noopener noreferrer"
            className={ "inline-flex items-center text-sm xl:text-base font-normal text-neutral-700 dark:text-neutral-300 py-2 px-4 xl:px-5 rounded-full hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-200"}
            to={item.href}
            aria-label={item.label}
          >
            {item.name}
        
        </NavLink>
      ))}
      {
        user != '' && user.role == 'edu_consultant' && user?.pro_status == 1?
        <>
         {/* <NavLink
            rel="noopener noreferrer"
            className={ "inline-flex items-center text-sm xl:text-base font-normal text-neutral-700 dark:text-neutral-300 py-2 px-4 xl:px-5 rounded-full hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-200"}
            to={"/company-stats"}
            aria-label={"Company In Outs"}
          >
            {"Company In Outs"}
        
        </NavLink> */}
         <NavLink
            rel="noopener noreferrer"
            className={ "inline-flex items-center text-sm xl:text-base font-normal text-neutral-700 dark:text-neutral-300 py-2 px-4 xl:px-5 rounded-full hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-200"}
            to={"/commissions"}
            aria-label={"Company In Outs"}
          >
            {"Comissions"}
        
        </NavLink>
        </>
       :null
      }
      {/* 
       {
        id: 3,
        href: "/company-stats",
        name: 'Company In Outs',
        label:'unirepRoleTranslate.nav_5'
        
      }, */}
      {
        user != '' && user.role == 'student'?
        <DropDownMenu label="unirepRoleTranslate.nav_13" translate={unirepRoleTranslate?.nav_13} navs={studentDropNavs} />:
        user != '' && user.role == 'uni_rep'?
        <DropDownMenu label="unirepRoleTranslate.nav_13" translate={unirepRoleTranslate?.nav_13} navs={unirepDrpDown} />:
        // user != '' && user.role == 'edu_consultant' && user?.pro_status == 0?
        // <DropDownMenu label="unirepRoleTranslate.nav_13" translate={unirepRoleTranslate?.nav_13} navs={educonDropDown} />:
        // user != '' && user.role == 'edu_consultant' && user?.pro_status == 1?
        // <DropDownMenu label="unirepRoleTranslate.nav_13" translate={unirepRoleTranslate?.nav_13} navs={educonProDropDown} />:
        user != '' && user.role == 'translator'?
        <DropDownMenu label="unirepRoleTranslate.nav_13" translate={unirepRoleTranslate?.nav_13} navs={translatorDropDowns} />:
        user != '' && user.role == 'teacher'?
        <DropDownMenu label="unirepRoleTranslate.nav_13" translate={unirepRoleTranslate?.nav_13} navs={teacherDropDown} />:
        user != '' && user.role == 'parent'?
        <DropDownMenu label="unirepRoleTranslate.nav_13" translate={unirepRoleTranslate?.nav_13} navs={parentDropDowns} />:null
      }
      
    </ul>
    )
  }
}
const mapStateToProps = (state)=>({
  user: state.Data.user,
  mainTransateData: state.Data.mainTransateData,
  unirepRoleTranslate: state.Data.unirepRoleTranslate,
})
export default connect(mapStateToProps)(Navigation)