import React, { Component } from 'react'
import { changeStateValue, resetStateValue } from '../../../../../../redux/MainReducer';
import { connect } from 'react-redux';
import { insertNewData } from '../../../../../../actions/WorkerActions';
import { mixinAlert } from '../../../../../../actions/Alerts';

export class StudentTotalModal extends Component {
    state={
        btnDisable:false
    }
    onChangeFile=(e)=>{
        this.props.changeStateValue({
            name:'studentTotalIncModal.file',
            value:e.target.files[0]
        })
    }
    removeImg=()=>{
        this.props.changeStateValue({
            name:'studentTotalIncModal.file',
            value:''
        })
    }
    getInpValue=(e)=>{
        this.props.changeStateValue({
            name: "studentTotalIncModal."+e.target.name,
            value: e.target.value
        })
    }

    closeModal=()=>{
        this.props.resetStateValue({name:"studentTotalIncModal"})
    }
    saveBtn=(data)=>{
        if(data?.file !="" && data?.amount !=""){
            this.setState({btnDisable:true});
            let formData = new FormData();
            for(const property in data){
                formData.append(property, data[property])
            }
            formData.append("student_id", this.props.student_id);
            formData.append("title", "contract");

            let url = "/student-total-finance";
            if(data?.id && data?.id !=""){
                url = `/student-total-finance/${data?.id}`;
                formData.append("_method", "put");
            }
            this.props.insertNewData(url, formData)
            .then(resp=>{
                this.setState({btnDisable:false});
                if(resp?.msg == "success"){
                    mixinAlert("success", "Contract upload successfully");
                    this.props.getAfterCall();
                    this.closeModal()
                }else{
                    mixinAlert("error", "Somethikng went wrong");
                }
            })
        }else{
            if(data?.file == ''){
                mixinAlert("warning", "Please, upload file");
            }else if(data?.amount ==""){
                mixinAlert("warning", "Please, add amount");
            }else{
                mixinAlert("warning", "Please, add amount or upload file");
            }
        }
    }
  render() {
    const {studentTotalIncModal} = this.props;
    return (
      <div className='background_shadow fixed flex justify-center items-center'>
        <div className='bg-white w-[600px] rounded-[10px] p-[20px]'>
            <div>
                <h2 className='text-blue-700 font-[500] text-[21px] text-center'>Upload signed contract (full)</h2>
            </div>
            <div>
               <div className='flex justify-center items-center flex-col p-[20px] gap-[10px]'>
                    <label className={`inline-flex ${studentTotalIncModal?.file !=""?"border border-blue-600 text-blue-600":""} w-[90px] h-[90px] cursor-pointer rounded-full text-center bg-gray-100 justify-center items-center text-[14px] flex-col gap-[10px]`} htmlFor='totalContFile'>
                        Upload file
                    </label>
                    <span className='text-[12px] '>Upload PDF file only</span>
                    {
                        studentTotalIncModal?.file !=""?
                        <button onClick={this.removeImg.bind(this)} className='bg-red-700 text-[12px] p-[2px_8px] text-white font-semibold rounded-[10px]'>Delete</button>:null
                    }
                    <input onChange={this.onChangeFile.bind(this)} type='file' className='hidden' accept='application/pdf' id='totalContFile' />
               </div>
                <div className='grid grid-cols-1 max-[500px]:grid-cols-1 gap-[20px] mt-[10px]'>
                   {/* <div className='flex flex-col'>
                     <span className='text-[12px] font-semibold'>Title</span>
                     <input value={studentTotalIncModal?.title} onChange={this.getInpValue.bind(this)} name="title" type='text' className='text-[14px] rounded-[6px]' placeholder='Title' />
                   </div> */}
                   <div className='flex flex-col'>
                     <span className='text-[12px] font-semibold'>Contract amount in AZN</span>
                     <input value={studentTotalIncModal?.amount} onChange={this.getInpValue.bind(this)} name="amount" type='number' className='text-[14px] rounded-[6px]' placeholder='0,000.0' />
                   </div>
                </div>
                <div className='flex justify-between items-center mt-[30px]'>
                    <button onClick={this.closeModal.bind(this)} className='bg-red-700 text-[12px] text-white p-[3px_5px] rounded-[6px] font-semibold'>Close</button>
                    <button onClick={this.saveBtn.bind(this, studentTotalIncModal)} className='bg-primary-700 text-[12px] text-white p-[3px_5px] rounded-[6px] font-semibold'>Upload & Submit</button>
                </div>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentTotalIncModal: state.Data.studentTotalIncModal
});
const mapDispatchToProps = {changeStateValue, resetStateValue, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(StudentTotalModal)
