import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getWorkerInOuts, getWorkerCompanyInOuts, insertNewData, deleteStudentTracking } from '../../../actions/WorkerActions';
import Searchable from 'react-searchable-dropdown'; 
import SectionHeader from '../../../components/page_component/SectionHeader';
import Swal from 'sweetalert2';
import AutoConpleteText from '../../../components/page_component/AutoConpleteText';
import ManatSvg from './ManatSvg';
export class EduconStats extends Component {
    state={
        years: '2024-2025',
        page: 'students',
        title: '',
        amount:'',
        file:'',
        modalPosition: false,
        url:'/company-finance',
        edu_year: ''
    }
    componentDidMount(){
        this.props.getWorkerInOuts(this.state.years);
        this.props.getWorkerCompanyInOuts(this.state.years)
    }

    getTotal(data, type){
       let total = 0;
       for(let i=0; i< data.length; i++){
        if(data[i]?.type == type){
            total+= +data[i]?.amount
        }
       }
       return Math.round(total);
    }
    selectdata=(value)=>{
        this.props.getWorkerInOuts(value)
        this.props.getWorkerCompanyInOuts(value)
        this.setState({years: value})

    }

    addFinanceBtn(title, amount, file,edu_year,  e){
        if(title != '' && amount != '' && edu_year != ''){
            let formData = new FormData();
            formData.append("title", title);
            formData.append("amount", amount);
            formData.append("file", file);
            formData.append("year", edu_year);
            
            if(this.state.url != '/company-finance'){
                formData.append("_method", "put")
            }
            this.props.insertNewData(this.state.url, formData)
            .then(resp=>{
                if(resp.msg == "success"){
                    this.setState({modalPosition:false});
                    this.props.getWorkerCompanyInOuts(this.state.years);
                    Swal.fire(
                        "Success",
                        "Expenses added successfully",
                        "success"
                    )
                }else{
                    Swal.fire(
                        "Error",
                        "Something went wrong",
                        "error"
                    )
                }
            })
        }
    }


    getTotalFinance(data){
        let total=0;
        for(let i = 0; i < data.length; i++){
            total+= +data[i]?.amount
        }
        return Math.round(total);
    }
  render() {
    const {workerInOuts, workerFinanceInOuts} = this.props;
    const {page, title, file, amount, modalPosition, edu_year} = this.state;
    console.log(workerFinanceInOuts)
    let result = workerInOuts.reduce(function (r, a) {
           
        r[a.student_id] = r[a.student_id] || [];
        r[a.student_id].push(a);
        return r;
    }, {});
    let option = [
        {
            label: "2020-2021",
            value: "2020-2021"
        },
        {
            label: "2021-2022",
            value: "2021-2022"
        },
        {
            label: "2022-2023",
            value: "2022-2023"
        },
        {
            label: "2023-2024",
            value: "2023-2024"
        },
        {
            label: "2024-2025",
            value: "2024-2025"
        },
    ];
    // console.log(workerFinanceInOuts)
    return (
      <div className='container'>
        <SectionHeader title="Finance" />
        <div className='flex gap-3 mb-3'>
            <button onClick={()=>this.setState({page: 'students'})} className={` p-2 text-sm rounded-md  font-semibold ${page== 'students'? 'bg-blue-600 text-white': 'border'}`}>Student Finance</button>
            <button onClick={()=>this.setState({page: 'company'})} className={` p-2 text-sm rounded-md  font-semibold ${page== 'company'? 'bg-blue-600 text-white': 'border'}`}>Educon Expenses</button>
        </div>
         {
            page == 'students'?
            <>
            <div>
                <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                Select Years
                </label>
                <div style={{ width: '300px' }} className={`block w-full border-neutral-200  
                    focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                    dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                    dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
                > 
                    <Searchable 
                        options={option}
                        onSelect={this.selectdata.bind(this)}
                        placeholder={"2024-2025"}
                    
                    />
                </div>
            </div>
            
            <h2 className='text-xl font-semibold mt-2 mb-2'>Total Ins: {this.getTotal(workerInOuts, 'incomes') } </h2>
            <h2 className='text-xl font-semibold mt-2 mb-2'>Total Outs: { this.getTotal(workerInOuts, 'outs')}</h2>
            <h2 className='text-xl font-semibold mt-2 mb-2'>Total Net: {this.getTotal(workerInOuts, 'incomes') - this.getTotal(workerInOuts, 'outs')}</h2>
            <div className='grid grid-cols-3 gap-5'>
                {
                    Object.values(result)?.map((data, i)=>{
                        return(
                            <div key={i} className='border rounded-xl p-5 '>
                                <h3 className='text-md'> {data[0]?.student?.full_name}</h3>
                                <table class="table-auto border w-full mt-3">
                                    <thead>
                                        <tr>
                                            <th className='border text-center w-1/3 p-1 text-sm'>Title</th>
                                            <th className='border text-center w-1/3 p-1 text-sm'>Pays</th>
                                            <th className='border text-center w-1/3 p-1 text-sm'>Amount</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data?.map((item, j)=>{
                                                return(
                                                    <tr key={j}>
                                                        <td className='border text-center w-1/3 p-1 text-sm'> <AutoConpleteText text={item?.title} /> {}</td>
                                                        <td className='border text-center w-1/3 p-1 text-sm'>{item?.type==="incomes"?<span className='text-green-600'>Ins</span>:<span className='text-red-600'>{item?.type}</span>}</td>
                                                        <td className='border text-center w-1/3 p-1 text-sm flex gap-[10px] w-full justify-center items-center'>{item?.amount} <ManatSvg /> </td>
                                                        
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                <table class="table-auto border w-full">
                                    <thead>
                                        <tr>
                                            <th className='border text-center w-1/3 p-1 text-sm'>Total Ins</th>
                                            <th className='border text-center w-1/3 p-1 text-sm'>Total Outs</th>
                                            <th className='border text-center w-1/3 p-1 text-sm'>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr >
                                            <td className='border text-center w-1/3 p-1 text-sm'>{this.getTotal(data, 'incomes')}</td>
                                            <td className='border text-center w-1/3 p-1 text-sm'>{this.getTotal(data, 'outs')}</td>
                                            <td className='border text-center w-1/3 p-1 text-sm'>{this.getTotal(data, 'incomes') - this.getTotal(data, 'outs')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )
                    })
                }
            </div>
            </>:
            <div>
                <div className='flex justify-end'>
                    <button onClick={()=>this.setState({modalPosition:true, title:'', amount:'', file:'', edu_year:''})} className={` p-2 text-sm rounded-md  font-semibold bg-blue-600 text-white`}>Add Finance</button>
                </div>
                <div>
                    <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                    Select Years
                    </label>
                    <div style={{ width: '300px' }} className={`block w-full border-neutral-200  
                        focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                        dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                        dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
                    > 
                        <Searchable 
                            options={option}
                            onSelect={this.selectdata.bind(this)}
                            placeholder={"2024-2025"}
                        
                        />
                    </div>
                </div>
                <h2 className='text-xl font-semibold mt-2 mb-2'>Total: {this.getTotalFinance(workerFinanceInOuts)}</h2>
                <div className='grid grid-cols-3 gap-5 mt-5'>
                    {
                        workerFinanceInOuts?.map((data, i)=>{
                            return(
                                <div key={i} className='border rounded-xl p-5 '>
                                     <h3 className='text-md'>Title: {data?.title}</h3>
                                     <h3 className='text-md mt-2'>Amount: {data?.amount}</h3>
                                    <div className='mt-2 flex justify-between'>
                                        {/* <button onClick={this.deleteFinance.bind(this, data?.id)} className='p-2 text-sm rounded-xl  font-semibold bg-red-600 text-white' >Delete</button> */}
                                        {
                                            data?.lock_status ==1?
                                        <button onClick={()=>this.setState({title: data?.title, amount: data?.amount, file: data?.file?data?.file:'', modalPosition:true, edu_year:data?.year, url: `/company-finance/${data?.id}`})} className='p-2 text-sm rounded-xl  font-semibold bg-blue-600 text-white' >Update</button>:
                                        <button className='p-2 text-sm rounded-xl  font-semibold bg-red-600 text-white' >Locked</button>

                                        }
                                        {
                                            data?.file_full_url != ''?
                                            <a className='p-2 text-sm rounded-xl  font-semibold bg-blue-600 text-white' target='_blank' href={data?.file_full_url}>View File</a>:null
                                        }
                                        
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
         }
         {
            modalPosition?
            <div className='background_shadow flex items-center justify-center'>
                <div className='bg-white p-3 w-1/3 shadow-gray rounded-lg'>
                    <h2>Add Finance</h2>
                    <div className='grid grid-cols-2 gap-3'>
                        <div className='mt-2'>
                            <label className='text-sm font-semibold'>Title</label>
                            <input placeholder='Title' value={title} onChange={(e)=>this.setState({title: e.target.value})} type='text' />
                            {
                                title ==''?
                                <span className='text-[12px] text-red-600'>This filed is required</span>:null
                            }
                            
                        </div>
                        <div className='mt-2'>
                            <label className='text-sm font-semibold'>Amount</label>
                            <input value={amount} placeholder='Amount' onChange={(e)=>this.setState({amount: e.target.value})} type='number' />
                            {
                                amount ==''?
                                <span className='text-[12px] text-red-600'>This filed is required</span>:null
                            }
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-3 mt-2'>

                        <div className='mt-2 flex flex-col '>
                            <span className='text-sm font-semibold'>File</span>
                            <label htmlFor='financeFileId' className={`border w-full inline-block rounded-2xl p-2 text-sm font-semibold cursor-pointer h-11${file != ''? 'bg-blue-600 text-white':''}`}>File</label>
                        {
                            file?
                            <button onClick={()=>this.setState({file:''})} className='text-red-500 text-sm cursor-pointer ml-auto'>Remove</button>:null
                        }
                            <input  onChange={(e)=>this.setState({file: e.target.files[0]})} accept='Image/*, application/pdf' id='financeFileId' className='invisible' type='file' />
                        
                        </div>
                        <div className='mt-2'>
                            <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300 `} data-nc-id="Label" >
                            Select Years
                            </label>
                            <div className={`block w-full border-neutral-200  
                                focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                                dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                                dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
                            > 
                                <Searchable 
                                    options={option}
                                    onSelect={(e)=>this.setState({edu_year:e})}
                                    placeholder={"Select Years"}
                                
                                />
                            </div>
                            {
                                edu_year ==''?
                                <span className='text-[12px] text-red-600'>This filed is required</span>:null
                            }
                        </div>
                    </div>
                    <div className='flex justify-between items-center'>
                        <button onClick={()=>this.setState({modalPosition:false})} className='p-2 text-sm rounded-md  font-semibold bg-red-600 text-white'>Close</button>
                        <button onClick={this.addFinanceBtn.bind(this, title, amount, file, edu_year)} className='p-2 text-sm rounded-md  font-semibold bg-blue-600 text-white'>Save</button>
                    </div>
                </div>
            </div>:null
         }
         
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    workerInOuts: state.Data.workerInOuts,
    workerFinanceInOuts: state.Data.workerFinanceInOuts,
});
const mapDispatchToProps = {getWorkerInOuts, getWorkerCompanyInOuts, insertNewData, deleteStudentTracking}
export default connect(mapStateToProps, mapDispatchToProps)(EduconStats)
