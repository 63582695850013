import React, { Component } from 'react'
import { insertData } from '../actions/WorkerActions';
import { connect } from 'react-redux';
import { mixinAlert } from '../actions/Alerts';

export class UniSeo extends Component {
    state={
        count:0,
        country_count:0,
        btnDisable:false
    }

    sendBtn(){
        this.setState({btnDisable: true});
        this.props.insertData("/uni-seo", {})
        .then(resp=>{
            mixinAlert("success", "Success");
            this.setState({btnDisable:false, count: this.state.count+1});
            setTimeout(()=>{
                this.sendBtn()
            },30000)
        })
    }
    sendBtn2(){
        // this.setState({btnDisable: true});
        // this.props.insertData("/country-seo", {})
        // .then(resp=>{
        //     mixinAlert("success", "Success");
        //     this.setState({btnDisable:false, country_count: this.state.country_count+1});
        //     setTimeout(()=>{
        //         this.sendBtn()
        //     },30000)
        // })
    }
  render() {
    const {count, btnDisable, country_count} = this.state;
    return (
      <div className='container p-[50px]'>
        <button onClick={this.sendBtn.bind(this)} disabled={btnDisable}> Start Uni</button>
        <h4>Count: {count}</h4>
        <br />
        <br />
        <br />
        <br />
        {/* <button onClick={this.sendBtn2.bind(this)} disabled={btnDisable}> Start Country</button>
        <h4>Count: {country_count}</h4> */}
      </div>
    )
  }
}
const mapStateToProps = (state)=>({

});
const mapDispatchToProps = {insertData}
export default connect(mapStateToProps, mapDispatchToProps)(UniSeo)
