import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getCompanyInOuts, getOneCompanyInOuts, insertNewData } from '../../actions/WorkerActions';
import SectionHeader from '../../components/page_component/SectionHeader';
import Searchable from 'react-searchable-dropdown'; 
import { DownloadIcon } from '@heroicons/react/solid';
import { changeStateValue } from '../../redux/MainReducer';
import Swal from 'sweetalert2';
import Loading from '../../components/page_component/Loading';
import { Link } from 'react-router-dom';
import RejectSvg from '../../components/cards/RejectSvg';
export class CompanyInOuts extends Component {
    state={
        years: '2024-2025',
        btnDisable: false,
        companyInsOuts: []
    }
    componentDidMount(){
        this.props.getCompanyInOuts("2024-2025")
    }

    getCount(arr){
        let total = 0;
        for(const property of arr){
            total+= +property?.amount;
        }

        return Math.round(total);
    }
    getAllIns(arr){
        let total = 0;
        for(const property of arr){
            total+= +this.getCount(property?.incomes);
        }
        
        return Math.round(total);
    }
    getAllOuts(arr){
        let total = 0;
        for(const property of arr){
            total+= +this.getCount(property?.outs);
        }
        for(let i = 0;  i< arr.length; i++){
            for(let j = 0; j <arr[i]?.company_stats.length; j++){
                total+= +arr[i]?.company_stats[j]?.amount
            }
        }
        return Math.round(total);
    }
    selectdata=(value)=>{
        this.props.getCompanyInOuts(value)
        this.setState({years: value})

    }

    getMoreView(year, company_id){
        this.props.getOneCompanyInOuts(year, company_id)
    }

    getModalTotals(arr, type){

        let total = 0;
        for(let i = 0; i < arr.length; i++){
            if(arr[i]?.type == type){
                total+= +arr[i]?.amount
            }
        }
        return Math.round(total);
    }
    getModalTotalsIns(arr, type){

        let total = 0;
        for(let i = 0; i < arr.length; i++){
            total+= +arr[i]?.amount
        }
        return Math.round(total);
    }
    
    closeModal(){
        this.props.changeStateValue({
            name: 'oneCompanyInOuts',
            value: []
        })
    }

    lockStudentFInance(year, companyId, status, student_id, type='company'){
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Yes, ${status == '0'? 'lock': 'unlock'} they!`
          }).then((result) => {
            if (result.isConfirmed) {
                this.setState({btnDisable:true});
                let url = "/change-finance-status";
                let formData = new FormData();
                formData.append("company_id", companyId);
                formData.append("type", type);
                formData.append("status", status);
                formData.append("year", year);
                formData.append("student_id", student_id);
                this.props.insertNewData(url, formData)
                .then(resp=>{
                    this.setState({btnDisable:false});
                    this.props.getCompanyInOuts(this.state.years)
                    if(type == "student"){
                        this.props.getOneCompanyInOuts(year, companyId)
                    }
                    
                    if(resp?.msg == "success"){
                       Swal.fire(
                        `${status == '0'? 'Locked': 'Unlocked'}`,
                        `Your file has been ${status == '0'? 'Locked': 'Unlocked'}.`,
                        'success'
                        ) 
                    }else{
                        Swal.fire(
                            'Error',
                            "Somthing went wrong",
                            'error'
                            ) 
                    }
                })
              
            }
        })
    }

    checkFinanceStatus(arr){
      
        let status = 1; 
        for(let i = 0; i < arr.length; i++){
            if(arr[i]?.finance_status?.length != 0){
                status = 0;
            }
        }
        return status;

    }

    changeCompFinanceLockUnlock(id, status){
        let formData = new FormData();
        formData.append("id", id);
        formData.append("status", status);
        this.props.insertNewData("/company-finance-lock-unlock", formData)
        .then(resp=>{
            let arr = [...this.state.companyInsOuts];
       
            let x = [];
            for(let i = 0; i <arr.length; i++){
                let newAr = [];
                for(let j = 0; j < arr[i].company_stats.length; j++){
                        console.log(arr[i].company_stats[j])
                    if( arr[i].company_stats[j].id == id){
                        newAr.push({
                            ...arr[i].company_stats[j],
                            lock_status: +status
                        })
                    }else{
                        newAr.push({
                            ...arr[i].company_stats[j]
                        })
                    }
                }
                x.push({
                    ...arr[i],
                    company_stats: newAr
                })
            }
            this.setState({companyInsOuts:x});
            this.props.getCompanyInOuts(this.state.years)
        })
    }
  render() {
    const {companyInOuts, oneCompanyInOuts, loader, loaderLong,} = this.props;
    const {years, btnDisable, companyInsOuts} = this.state;
    // console.log(companyInsOuts)
    let option = [
        {
            label: "2020-2021",
            value: "2020-2021"
        },
        {
            label: "2021-2022",
            value: "2021-2022"
        },
        {
            label: "2022-2023",
            value: "2022-2023"
        },
        {
            label: "2023-2024",
            value: "2023-2024"
        },
        {
            label: "2024-2025",
            value: "2024-2025"
        },
    ];
    // 
    console.log(companyInOuts)
    return (
      <div className='container'>
        <SectionHeader title="Company In & Outs" />
        <div>
            <div>
                <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                Select Years
                </label>
                <div style={{ width: '300px' }} className={`block w-full border-neutral-200  
                    focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                    dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                    dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
                > 
                    <Searchable 
                        options={option}
                        onSelect={this.selectdata.bind(this)}
                        placeholder={"2024-2025"}
                    
                    />
                </div>
            </div>
        </div>
        <div className='grid grid-cols-3 gap-6 mt-5'>
            {
                companyInOuts.map((data, i)=>{
                    if(
                        data?.company_id != 17 && 
                        data?.company_id != 18 && 
                        // data?.company_id != 19 && 
                        data?.company_id != 22 && 
                        data?.company_id != 24 && 
                        data?.company_id != 25 && 
                        data?.company_id != 26 && 
                        data?.company_id != 27 && 
                        data?.company_id != 28
                    ){
                        return(
                            <div key={i} className='border rounded-xl p-3 flex flex-col justify-between'>
                                <h3 className='text-center font-semibold'>{data?.company_name}</h3>
                                <div>
                                    <div className='flex justify-between mt-2'>
                                        <span className='border w-full text-center font-semibold text-sm p-1'>Worker</span>
                                        <span className='border w-full text-center font-semibold text-sm p-1'>Ins</span>
                                        <span className='border w-full text-center font-semibold text-sm p-1'>Outs</span>
                                        <span className='border w-full text-center font-semibold text-sm p-1'>Total</span>
                                    </div>
                                    {
                                        data?.workers?.map((item, j)=>{
                                            return(
                                                <div key={j} className='flex justify-between'>
                                                    <span className='border w-full text-center  text-sm p-1'>{item?.full_name}</span>
                                                    <span className='border w-full text-center  text-sm p-1'>{this.getCount(item?.incomes)}</span>
                                                    <span className='border w-full text-center  text-sm p-1'>{this.getCount(item?.outs)}</span>
                                                    <span className='border w-full text-center  text-sm p-1'>{this.getCount(item?.incomes) - this.getCount(item?.outs)}</span>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className='flex justify-between'>
                                        <span className='border w-full text-center  text-sm p-1 font-bold'>All</span>
                                        <span className='border w-full text-center  text-sm p-1 font-bold'>{this.getAllIns(data?.workers)}</span>
                                        <span className='border w-full text-center  text-sm p-1 font-bold'>{this.getAllOuts(data?.workers)}</span>
                                        <span className='border w-full text-center  text-sm p-1 font-bold'>{this.getAllIns(data?.workers) - this.getAllOuts(data?.workers)}</span>
                                    </div>
                                </div>
                                <div className='flex justify-between mt-2'>
                                    <button disabled={btnDisable} onClick={this.lockStudentFInance.bind(this,years,  data?.company_id, this.checkFinanceStatus(data?.workers) == 1? '0': '1')} className={this.checkFinanceStatus(data?.workers) == '0'?`bg-red-700 text-sm p-2 rounded-xl text-white cursor-pointer font-semibold`:'border text-sm p-2 rounded-xl  cursor-pointer font-semibold'}>{this.checkFinanceStatus(data?.workers) == 1? 'Lock All': 'Unlock All'}</button>
                                    <button disabled={btnDisable} onClick={(e)=>this.setState({companyInsOuts: data.workers})} className={'bg-white-700 text-sm p-2 rounded-xl text-black border cursor-pointer font-semibold'}>View Ed. Ex</button>
                                    <button onClick={this.getMoreView.bind(this, years, data?.company_id)} className='bg-blue-700 text-sm p-2 rounded-xl text-white cursor-pointer font-semibold'>View More</button>
                                </div>
                            </div>
                        )
                    }
                    
                })
            }
            
        </div>
        {
            oneCompanyInOuts?.length != 0?
            <div className='background_shadow flex items-center justify-center'>
                <div style={{ maxHeight: '70vh', overflow:'auto' }} className='bg-white rounded-xl p-4 w-[1400px]'>
                    <div className='flex items-center justify-between border '>
                        <span style={{ minWidth: '120px' }} className='border w-1/5 text-center p-2 text-base font-bold'>Student</span>
                        <span className='border w-1/5 text-center p-2 text-base font-bold'>Educon</span>
                        <span className='border w-1/5 text-center p-2 text-base font-bold'>Cont</span>
                        <span className='border w-1/5 text-center p-2 text-base font-bold'>Ins</span>
                        <span className='border w-1/5 text-center p-2 text-base font-bold'>Outs</span>
                        <span className='border w-1/5 text-center p-2 text-base font-bold'>Total</span>
                        <span className='border w-1/5 text-center p-2 text-base font-bold'>Files</span>
                        <span className='border w-1/5 text-center p-2 text-base font-bold'>Status</span>
                        <span className='border w-1/6 text-center p-2 text-base font-bold'>Cancelled</span>
                    </div>
                    {
                        oneCompanyInOuts?.map((worker, i)=>{
                           return(
                            worker.worker_students?.map((student, j)=>{
                                return(
                                    <div key={Math.random()} className='flex  justify-between border '>
                                        <Link target='_blank' to={`/student-info/${student?.student?.id}`} style={{ minWidth: '120px' }} className='border w-1/5 text-center p-2 text-base inline-flex items-center gap-[10px]'> {j+1}. {student?.student?.full_name}</Link>
                                        <span className='border w-1/5 text-center p-2 text-base '>{worker?.full_name}</span>
                                        <span className='border w-1/5 text-center p-2 text-base '>{this.getModalTotalsIns(student?.student?.total_ins, 'incomes')} 
                                           
                                        </span>
                                        <span className='border w-1/5 text-center p-2 text-base '>{this.getModalTotals(student?.student?.student_finance, 'incomes')}</span>
                                        <span className='border w-1/5 text-center p-2 text-base '>{this.getModalTotals(student?.student?.student_finance, 'outs')}</span>
                                        <span className='border w-1/5 text-center p-2 text-base '>{this.getModalTotals(student?.student?.student_finance, 'incomes') - this.getModalTotals(student?.student?.student_finance, 'outs')}</span>
                                        <span className='border w-1/5 text-center p-2 text-base flex justify-center gap-2'>
                                            {/* {
                                                student?.student?.student_finance?.map((file,k)=>{
                                                    if(file?.file){
                                                        return(
                                                            <a download={true} key={k} target='_blank' href={file?.file?.file_full_url}>
                                                                <DownloadIcon className='w-6 cursor-pointer' />
                                                          
                                                            </a>
                                                        )
                                                    }
                                                })
                                            } */}
                                             <div>
                                                {
                                                    student?.student?.total_ins?.map((x, k)=>{
                                                        return(
                                                            <a target='_blank' key={k} href={x?.file_full_url}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 52 52" fill="#1E398F"><path d="M28.6 11.4h5.1a1.11 1.11 0 0 0 1.1-1.1 1.32 1.32 0 0 0-.3-.8l-7.2-7.1a.89.89 0 0 0-.7-.3 1.11 1.11 0 0 0-1.1 1.1v5.1a3.12 3.12 0 0 0 3.1 3.1zM49.83 32.45A1.43 1.43 0 0 0 48.44 31h-12A1.43 1.43 0 0 0 35 32.44v1.44a1.43 1.43 0 0 0 1.4 1.44h6.14l-11 11a1.42 1.42 0 0 0 0 2l1 1a1.54 1.54 0 0 0 1.09.45 1.32 1.32 0 0 0 .94-.38l11-11v6A1.43 1.43 0 0 0 47 45.83h1.53a1.43 1.43 0 0 0 1.47-1.4zM18.35 20.68h-1.46v4.93h1.46a1.56 1.56 0 0 0 1.56-1.1 3.93 3.93 0 0 0 .24-1.44 3.61 3.61 0 0 0-.36-1.77 1.53 1.53 0 0 0-1.44-.62zM11.29 20.93a1.27 1.27 0 0 0-.84-.25H9v2.23h1.46a1.18 1.18 0 0 0 .84-.28 1.09 1.09 0 0 0 .3-.86 1 1 0 0 0-.33-.84z"></path><path d="M33.24 16H25.4a4.67 4.67 0 0 1-4.7-4.6V3.6a1.42 1.42 0 0 0-1.34-1.5H6.8a4.67 4.67 0 0 0-4.7 4.6v29.4a4.6 4.6 0 0 0 4.62 4.6H24.6v-.1a15.92 15.92 0 0 1 10.2-14.9v-8.1a1.58 1.58 0 0 0-1.56-1.6zm-20.75 7.65a2.93 2.93 0 0 1-1.89.56H9v2.71H7.49v-7.55h3.24a2.64 2.64 0 0 1 1.76.63 2.3 2.3 0 0 1 .66 1.8 2.35 2.35 0 0 1-.66 1.85zm8.66 1.9A2.73 2.73 0 0 1 18.62 27h-3.26v-7.63h3.26a3.85 3.85 0 0 1 1.17.17 2.49 2.49 0 0 1 1.28.95 3.43 3.43 0 0 1 .54 1.22 5.6 5.6 0 0 1 .14 1.29 5 5 0 0 1-.6 2.55zm7.92-4.84h-3.81v1.74h3.34v1.31h-3.34v3.16h-1.57v-7.54h5.38z"></path></svg>
                                                            </a>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </span>
                                        <span className='border w-1/5 text-center p-2 text-base flex justify-center gap-2'>
                                      
                                            {
                                                student?.student?.finance_lock == '1'?
                                                <button onClick={this.lockStudentFInance.bind(this, student?.student?.education_year, worker?.company_id, '0',student?.student?.id, "student")} className='bg-yellow-500 text-sm font-semibold p-2 rounded-xl text-white'>Lock</button>:
                                                <button onClick={this.lockStudentFInance.bind(this, student?.student?.education_year, worker?.company_id, '1',student?.student?.id, "student")} className='bg-red-600 text-sm font-semibold p-2 rounded-xl text-white'>Unlock</button>
                                            }
                                            
                                        </span>
                                        <span className='border w-1/6 text-center p-2 text-base flex justify-center gap-2 inline-flex justify-center items-center'>
                                            <RejectSvg color={student?.student?.reject_status==0?"#333":"red"} />
                                        </span>
                                    </div>
                                )
                            })
                           )
                        })
                    }
                    <div className='flex justify-end mt-2'>
                        <button onClick={this.closeModal.bind(this)} className='bg-blue-700 text-sm p-2 rounded-xl text-white cursor-pointer font-semibold'>Close</button>
                    </div>
                </div>
            </div>:null
        }
        {
            companyInsOuts?.length != 0?
            <div className='background_shadow flex items-center justify-center'>
            <div style={{ maxHeight: '70vh', overflow:'auto' }} className='bg-white rounded-xl p-4 w-[1400px]'>
                <table class="table-auto border w-full">
                    <thead>
                        <tr>
                            <th className='border p-1 text-sm w-1/5'>Educon</th>
                            <th className='border p-1 text-sm w-1/5'>Title</th>
                            <th className='border p-1 text-sm w-1/5'>Amount</th>
                            <th className='border p-1 text-sm w-1/5'>File</th>
                            <th className='border p-1 text-sm w-1/5'> Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            companyInsOuts?.map((data, i)=>{
                                return(
                                    data?.company_stats?.map((item, j)=>{
                                        return(
                                           <tr key={`${i}-${j}`}>
                                                <td className='border p-1 text-sm w-1/5'>{j+1}. {item?.worker?.full_name}</td>
                                                <td className='border p-1 text-sm w-1/5'>{item?.title}</td>
                                                <td className='border p-1 text-sm w-1/5'>{item?.amount}</td>
                                                <td className='border p-1 text-sm w-1/5'>{
                                                    item?.file_full_url != ''?
                                                    <a download={true}  target='_blank' href={ item?.file_full_url}>
                                                        <DownloadIcon className='w-6 cursor-pointer' />
                                                    </a>:null
                                                }</td>
                                                <td className='border p-1 text-sm w-full inline-flex justify-center'>
                                                {
                                                    item?.lock_status == 1?
                                                    <button onClick={this.changeCompFinanceLockUnlock.bind(this,item?.id, '0')} className='bg-yellow-500 text-sm font-semibold p-2 rounded-xl text-white'>Lock</button>:
                                                    <button onClick={this.changeCompFinanceLockUnlock.bind(this, item?.id,'1')} className='bg-red-600 text-sm font-semibold p-2 rounded-xl text-white'>Unlock</button>
                                                }
                                                </td>
                                            </tr> 
                                        )
                                    })
                                    
                                )
                            })
                        }
                        
                    </tbody>
                </table>
                <div className='flex justify-end mt-2'>
                        <button onClick={()=>this.setState({companyInsOuts:[]})} className='bg-blue-700 text-sm p-2 rounded-xl text-white cursor-pointer font-semibold'>Close</button>
                    </div>
            </div>
        </div>:null
        }
        
        {
             loader || loaderLong? 
             <Loading />:null
            
           }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    companyInOuts: state.Data.companyInOuts,
    oneCompanyInOuts: state.Data.oneCompanyInOuts,
    loader: state.Data.loader,
    loaderLong: state.Data.loaderLong,
});
const mapDispatchToProps = {getCompanyInOuts, getOneCompanyInOuts, changeStateValue, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(CompanyInOuts)
